<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.dni"
              :label="t('users.dni')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.fullname"
              :label="t('users.fullname')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <div class="d-flex justify-space-between">
              <v-autocomplete
                v-model="phoneCode"
                dense
                outlined
                :items="phoneCodeOptions"
                item-text="text"
                item-value="value"
                style="
                  width: 35%;
                  max-width: 100px;
                "
                :append-icon="option===2 ? null : icons.mdiMenuDown"
                :readonly="option===2"
                hide-details
                :rules="[required]"
                @change="() => phoneNumber = null"
              >
                <template v-slot:item="{ item }">
                  <img
                    :src="item.image"
                    :alt="item.value"
                    width="20"
                    height="auto"
                    class="mr-2"
                  >
                  {{ item.text }}
                </template>
              </v-autocomplete>

              <v-text-field
                v-model="phoneNumber"
                :label="t('users.phone')"
                dense
                outlined
                :disabled="!phoneCode"
                :readonly="option===2"
                class="pl-1"
                :rules="[required, validatePhone(phoneNumber, phoneCode)]"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="dataComputed.note"
              :label="$t('transactions.note')"
              outlined
              rows="2"
              :readonly="option===2"
              :rules="[]"
            ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiMenuDown } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import parsePhoneNumber from 'libphonenumber-js'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const {
      phoneCodeOptions,
      initPhoneConfig,
    } = usePhone()

    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const initCode = ref('')
    const phoneCode = ref('')
    const phoneNumber = ref('')
    const dataAcount = ref({})
    const initialData = {
      dni: null,
      fullname: null,
      phone_number: null,
      note: null,
      status: 'A',
    }

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const initPhone = () => {
      setTimeout(() => {
        if (props.option === 1) {
          const countryCode = getCountryCode()
          if (countryCode) {
            const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
            if (codeFinded) initCode.value = countryCode
            else initCode.value = 'US'
          }
          phoneCode.value = initCode.value

          dataComputed.value.status = 'A'
        }

        if (props.option !== 1) {
          if (props.dataParams.phone_number) {
            const config = initPhoneConfig(props.dataParams.phone_number)
            phoneCode.value = config.code
            phoneNumber.value = config.phone_number
          }
        }
      }, 400)
    }

    const validatePhone = (e, code) => {
      if (code && e) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            dataComputed.value.phone_number = phoneNumberJS.number
            dataComputed.value.phone_code = phoneNumberJS.country
            dataComputed.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            return true
          }
        }
      }

      return 'Invalid number'
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        dni: null,
        fullname: null,
        phone_number: null,
        note: null,
        status: 'A',
      })
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    onMounted(async () => {
      await initPhone()
    })

    return {
      // data
      form,
      dataAcount,
      initialData,
      phoneCodeOptions,
      phoneCode,
      phoneNumber,

      // computed
      dataComputed,
      statusOptions,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,

      // validations
      required,
      emailValidator,
      integerValidator,
      validatePhone,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>
